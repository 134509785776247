export const data = [
    {
        id:1,
        name:'Reshta Wann',
        designation:'Senior Engineer',
        img:'assets/images/team-member/team-img01.jpg',      
    },
    {
        id:2,
        name:'Alena John',
        designation:'Graphic Designer',
        img:'assets/images/team-member/team-img02.jpg',     
    },
    {
        id:3,
        name:'Kristal Leo',
        designation:'Mannager',
        img:'assets/images/team-member/team-img03.jpg',
    },
    {
        id:4,
        name:'Alena John',
        designation:'Founder',
        img:'assets/images/team-member/team-img04.jpg',
    },
]