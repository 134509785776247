import React from "react";
import Slider from "../components/Slider";
import './Home.css';
import Counter from "./Counter";



// import loadscript from "../loadscript";

function Home() {

  return (
    <>
      <Slider />
      <Counter />
     
    </>
  );
}

export default Home;
