import React from 'react'
import PortfolioSlider from "../components/PortfolioSilder";

function PortfolioDetails() {
  return (
   <>
   <div className="site-main p-0">
        <PortfolioSlider />
      </div>
   </>
  )
}

export default PortfolioDetails
