export const data = [
    {
        id:1,
        name:'Manges Vasiya',
        designation:'Founder-vyas computers',
        img:'assets/images/testimonial/02.jpg',
        content:'The design for my new website was conceived in a short amount of time. After a few initial changes, the final product came out just the way we hoped. We routinely receive compliments about the quality of our website. '
    },
    {
        id:2,
        name:'Sarvjeet Yadav',
        designation:'CEO & Founder-Riya optics',
        img:'assets/images/testimonial/01.jpg',
        content:'I had a few things I needed help with on this template... Their customer service was amazing and helped me out many times. All fits and works well and good!! Top marks. One of the complete template with different requirements. '
    },
    {
        id:3,
        name:'Amarjeet Yadav',
        designation:'Founder-Uttar Yadav Yuva Sangh',
        img:'assets/images/testimonial/03.jpg',
        content:'All fits and works well and good!! Top marks. One of the complete template with different requirements. Thanks a lot for such great features, pages, shortcodes and home variations. Incredible Job, Thanks a Lot Octave Infosys Team'
    },
]